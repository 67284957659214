<template>
  <div id="project">
    <Header
      :user="loggedUser"
      :currentRouteName="$route.name"
    />
    <b-container class="project-container">
      <b-row>
        <b-col>
          <h4 class="text-left">Proyectos</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <ProjectInfoCard
            :loggedUser="loggedUser"
          />
        </b-col>
        <b-col col>
          <slot />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import ProjectInfoCard from '../components/ProjectInfoCard/ProjectInfoCard.vue'

export default {
  name: 'Projects',
  components: { Header, ProjectInfoCard },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    }
  }
}
</script>

<style lang="less">
  @import 'projects.less';
</style>
