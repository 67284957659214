<template>
  <div>
    <Projects v-if="true">
      <b-card
        :title="'Crear Proyecto'"
        class="user-create-card"
        align="left"
      >
        <ProjectForm
          ref="projectForm"
          :form_prop.sync="project"
          @onNewEmployee="onNewEmployee"
          @onNewHardware="onNewHardware"
        />
        <b-row class="footer">
          <b-col class="footer-buttons">
            <b-button variant="primary" @click="onSave(true)">
              Guardar cambios
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </Projects>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Projects from '../../Projects.vue'
import ProjectForm from '@/components/Business/Project/Form/ProjectForm.vue'

export default {
  name: 'ProjectCreate',
  components: {
    Projects,
    ProjectForm
  },
  created () {
    this.setEmpty()
    this.getAllEmployees()
    this.getAllHardware()
    this.getAllVenues()
  },
  computed: {
    ...mapGetters({ project: 'project/current' }),
    ...mapGetters({ venues: 'venues/all' })
  },
  methods: {
    ...mapMutations('project', ['setEmpty']),
    ...mapActions('employee', { getAllEmployees: 'getAll' }),
    ...mapActions('hardware', { getAllHardware: 'getAll' }),
    ...mapActions('venues', { getAllVenues: 'getAll' }),
    async onSave (showConfirmation) {
      const isFormValid = await this.$refs.projectForm.$refs.projectForm.validate()
      if (!isFormValid) {
        return
      }
      this.lockScreen()
      const projectToCreate = { ...this.project }
      const venueExists = projectToCreate.venue != null
      if (venueExists) {
        projectToCreate.venue = projectToCreate.venue.id
        projectToCreate.venueExtraName = null
      } else {
        projectToCreate.venue = null
      }
      if (!this.project.id) {
        this.$store.dispatch('project/create', projectToCreate)
          .then(() => {
            this.unlockScreen()
          })
      } else {
        this.$store.dispatch('project/update', projectToCreate)
          .then(() => {
            this.unlockScreen()
            if (showConfirmation) {
              this.$store.dispatch('showConfirmationModal')
              this.$router.push({ name: 'project-list' })
            }
          })
      }
    },
    onNewEmployee (id) {
      this.$bvModal.hide(id)
      this.onSave(false)
      this.$router.push({ name: 'employee-create' })
    },
    onNewHardware (id) {
      this.$bvModal.hide(id)
      this.onSave(false)
      this.$router.push({ name: 'hardware-create' })
    }
  }
}
</script>

<style lang="less" scoped>
@import "projectCreate";
</style>
